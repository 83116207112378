<template>
    <div class="card card-default card-buy-now">

      <div class="card-body">

        <div class="mb-3">
          <iframe width="100%" height="600px" :src="'https://www.youtube.com/embed/dvkivncdgYM' + '?autoplay=0'" frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div class="mb-3">
          <iframe width="100%" height="600px" :src="'https://www.youtube.com/embed/c1iZnnqddv8' + '?autoplay=0'" frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div class="mb-5" v-if="category">

          <h4 class="animated-fast fadeInDown"><i :class="category.icon" class="mr-2"></i> FAQ {{ category.name }}</h4>

          <accordion-card v-for="(entry, index) in category.questions" @opened="opened(entry)" :key="'index' + index" class="animated-fast fadeInLeft">
            <template v-slot:title>
              {{ entry.question }}
            </template>

            <markdown-parser :horizontal-line="false" :highlight="false" :table="false" :source="entry.answer"></markdown-parser>

          </accordion-card>

        </div>

      </div>
    </div>
</template>
<style lang="scss">

</style>
<script>
import AccordionCard from "../../components/AccordionCard.vue";
import MarkdownParser from "@/components/Common/MarkdownParser.vue";

export default {
  components: {
    MarkdownParser,
    AccordionCard,
  },
  data() {
    return {
      term: null,
      product: null,
      amount: 0,
      category: null,
    }
  },
  beforeCreate() {
    this.$api.get('support/categories/participation', {language: this.$i18n.locale}).then(response => {
      this.category = response.data;
    });
  },
  methods: {
    opened(entry) {
      if (!entry.opened) {
        this.$api.post('support/question/' + entry.id + '/opened');
        entry.opened = true;
      }
    },
  }
}
</script>